import { getAuthorizedApi } from "api/apiInstance";
import HomePage from "components/homePage";
import { APISIPL } from "constants/cricket";
// import { useRouter } from "next/router";
import { logger } from "logging/log-util";
import { getCtxPayload } from "utils/loggerHelper";
import { setPageTTL } from "utils/setPageTtl";
//import { getSession } from "next-auth/react";
// export const config = { amp: "hybrid" };
import { v4 as uuidv4 } from "uuid";

export default function Home(props) {
  return <HomePage {...props} loadAll = {false} />;
}

export async function getServerSideProps(ctx) {
  //const session = await getSession(ctx);
  const lallantop = JSON.parse(ctx?.req?.cookies?.lallantop || "{}");

  // Attach the UUID to the logger context -> Logging
  const ctxId = uuidv4();
  const loggerWithCtx = logger.child({
    ctxId,
    userId: lallantop?.userId,
    page: "Home",
  });
  const ctxPayload = getCtxPayload(ctx, { label: "Home CSR : Page Request" });
  loggerWithCtx.info(ctxPayload);

  const httpClient = await getAuthorizedApi(lallantop, loggerWithCtx);
  try {
    // const response = await httpClient.get(
    //   `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v2/web/featured?limit=10&skip=1&type=video,text`
    // );

    const [
      response,
      ShortsResponse,
      TopShowsResponse,
      homePageData,
      liveData,
      // quizData,
      // topicData,
      topShowsWidgetDataResponse,
      liveBlogWidgetResponse,
      homePageVideos,
      authorData,
      topPodcast,
      largeBannerDesktop,
      carouselBannersDesktop,
      carouselBannersMobile,
      cricketTopics
      
    ] = await Promise.all([
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v2/web/featured?limit=12&skip=1&type=video,text,liveblog`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .post(`${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/short/list`, {
          limit: 12,
          skip: 1,
        })
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/series/seriesByType?limit=10&skip=1&type=video`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v2/web/home?type=video,text,liveblog&limit=12&skip=1&accessType=web`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/live/listWithStreamUrl?limit=10&skip=1`
        )
        .catch((err) => {
          return { data: [] };
        }),
      // httpClient.get(
      //   `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v2/quiz/status?limit=50&skip=1&state=henceForth`
      // ).catch((err)=>{
      //   return {data: []}
      // }),
      // httpClient.get(
      //   `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/topicItem/getContentsBySlugAndType?limit=6&skip=1&slug=chunav-yatra-2024&type=video`
      // ).catch((err)=>{
      //   return {data: []}
      // }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/series/video-homepage?limit=10&skip=1&episodeLimit=5`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/post/listLiveBlogByStatusWithEvents?limit=3&skip=1&status=live&showEvents=true&eventLimit=10&eventSkip=1`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/web/homePageArticleWidget?limit=6&skip=1`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/author/list?limit=22&skip=1`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/spreaker/data?limit=1&topShow=false&topEpisode=true&skip=0`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/banner/all?source=WEB&category=home-desktop-big`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/banner/all?source=WEB&category=home-desktop`
        )
        .catch((err) => {
          return { data: [] };
        }),
      httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/banner/all?source=WEB&category=home-mweb`
        )
        .catch((err) => {
          return { data: [] };
        }),
        httpClient
        .get(
          `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/topicItem/getContentsBySlugAndType?limit=10&skip=1&slug=champions-trophy&type=text,video`
        )
        .catch((err) => {
          return { data: [] };
        }),
    ]);
    // let cricketResponse;

    // try {
    //   const cricketResult = await fetch(
    //     "https://production-rest-microservice.thesportstak.com/english/api/v2/featureCard/home"
    //   );
    //   cricketResponse = await cricketResult?.json();
    //   cricketResponse = cricketResponse?.response?.items;
    //   console.log("try cricket11");
    // } catch (error) {
    //   cricketResponse = "";
    //   console.log("catch cricket11");
    // }

    let featuredPosts = [];
    let bannerData = [];
    let shortsData = [];
    let topShowsData = [];
    let NewsPageData = [];
    let quizHomeData = [];
    let electionTopicData = [];
    let liveBlogWidgetData = [];
    let homePageVideosData = [];
    let authorWidgetData = [];
    let topPodcastData = [];
    let largeBannerDesktopData = [];
    let carouselBannersDesktopData = [];
    let carouselBannersMobileData = [];

    bannerData = {
      largeBannerDesktopData: largeBannerDesktop?.data?.result,
      carouselBannersDesktopData: carouselBannersDesktop?.data?.result,
      carouselBannersMobileData: carouselBannersMobile?.data?.result,
    };

    let topShowsWidgetData = [];
    let liveShowData = liveData?.data?.result;
    if (response.data && response.data.result) {
      featuredPosts = response.data.result.featuredPosts;
      // bannerData = response.data.result.banners;
    }

    if (authorData.data && authorData.data.result) {
      authorWidgetData = authorData.data.result.columnists;
      console.log("authorWidgetData", authorWidgetData);
    }

    if (ShortsResponse.data && ShortsResponse.data.result) {
      shortsData = ShortsResponse.data.result.entities;
    }
    if (TopShowsResponse.data && TopShowsResponse.data.result) {
      topShowsData = TopShowsResponse.data.result.series;
    }
    if (topPodcast.data && topPodcast.data.result) {
      topPodcastData = topPodcast.data.result.podcasts.topEpisodes;
    }
    if (homePageData.data && homePageData.data.result) {
      NewsPageData = homePageData.data.result.categoryPosts[0];
    }
    if (liveBlogWidgetResponse.data && liveBlogWidgetResponse.data.result) {
      let liveBlogWidgetDataTemp = liveBlogWidgetResponse.data.result;
      liveBlogWidgetDataTemp?.map((data, index) => {
        if (data?.events?.length > 2) {
          liveBlogWidgetData.push(data);
        }
      });
    }
    // if (quizData.data && quizData.data.result.quizzes) {
    //   quizHomeData = quizData.data.result.quizzes;
    // }
    if (
      topShowsWidgetDataResponse?.data &&
      topShowsWidgetDataResponse?.data?.result?.videoShows
    ) {
      topShowsWidgetData = topShowsWidgetDataResponse?.data?.result?.videoShows;
    }
    // if (topicData && topicData?.data && topicData?.data?.result?.entities) {
    //   electionTopicData = topicData?.data?.result?.entities;
    // }
    if (
      homePageVideos &&
      homePageVideos?.data &&
      homePageVideos?.data?.result?.entities
    ) {
      homePageVideosData = homePageVideos?.data?.result;
    }
    let cricketData = null

    const cricketTopicData =  cricketTopics

    if (cricketTopicData && cricketTopicData?.data && cricketTopicData?.data?.result?.entities) {
      cricketData = cricketTopicData?.data?.result?.entities;
    }

    const data = await fetch(
      "https://vsfeed.intoday.in/visualstories/feed/api.php?site=lallantop_web&type=hp"
    );
    const responseVs = await data.json();



    let pointsData = null
    let scoreData = {
      live : null,
      upcoming : null,
      recent : null
    }


      
 
     const results = await Promise.all([
      httpClient.get(
        APISIPL['matches']['pointsTable']
      ).then(res => {
        return res
      }).catch(err => {
        console.log(err , ' error in the sports.feed points table api ');
        
      }),
      httpClient.get(
        APISIPL['matches']['live']
      ).then((response) => {
        return response.data
    }).catch(err => {
      console.log(err , ' error in sports.feed live api');
      
    }),
      httpClient.get(
        APISIPL['matches']['upcoming']
      ).then((response) => {
        return response.data
    }).catch(err => {
      console.log(err , ' error in sports.feed upcoming api');
      
    }),
    httpClient.get(
      APISIPL['matches']['recent']
    ).then((response) => {
      return response.data
  }).catch(err => {
    console.log(err , ' error in sports.feed upcoming api');
    
  }),

    ])



 

    if(results[0]?.data){
      pointsData = results[0].data
    }

     //checking if any of the matches are live 
     if(results[1] ){
      scoreData = {
        ...scoreData ,
        live : results[1]
      }
      
    }
    else{
      scoreData = {
        ...scoreData ,
        live : null
      }
      
    }
    //checking if any of the matches are upcoming 
    if(results[2] ){
      scoreData = {
        ...scoreData ,
        upcoming : results[2]
      }
      
    }
    else{
      scoreData = {
        ...scoreData ,
        upcoming : null
      }
      
    }
      //checking if any of the matches are recent 
      if(results[3] ){
        scoreData = {
          ...scoreData ,
          recent : results[3]
        }
        
      }
      else{
        scoreData = {
          ...scoreData ,
          recent : null
        }
        
      }

    try {
      ctx?.res?.setHeader("Cache-Control", `max-age=120, stale-if-error=86400`);
    } catch (error) {
      console.error("cache error", error);
    }
    setPageTTL(ctx , 5)
   
    return {
      props: {
        //session,
        featuredPosts,
        bannerData,
        shortsData,
        topShowsData,
        NewsPageData,
        liveShowData,
        // cricketResponse,
        quizHomeData,
        electionTopicData,
        topShowsWidgetData,
        responseVs,
        liveBlogWidgetData,
        homePageVideosData,
        authorWidgetData,
        topPodcastData,
        pointsData , 
        scoreData,
        cricketData
      },
    };
  } catch (error) {
    // try {
    //   ctx?.res?.setHeader(
    //     "Cache-Control",
    //     `stale-if-error=86400`
    //   );
    // } catch (error) {
    //   console.error("cache error", error);
    // }
    loggerWithCtx.error({ ...ctxPayload, ...error });
    console.error(error);
    return {
      props: {
        //session,
        featuredPosts: [],
        bannerData: [],
        shortsData: [],
        topShowsData: [],
        NewsPageData: [],
        liveShowData: [],
        cricketResponse: [],
        quizHomeData: [],
        electionTopicData: [],
        topShowsWidgetData: [],
        homePageVideosData: [],
        // responseVs : []
      },
    };
  }
}
